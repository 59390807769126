<div class="outer">
  <a href="https://firebasestorage.googleapis.com/v0/b/portfolio-3b4b2.appspot.com/o/Resume-Biruk-Mengistu%2Bw%2Blab.pdf?alt=media&token=f5a43df5-ff39-4471-b009-cbca384a320b">
  <div class="resume">
    Download Resume
  </div>
  </a>
  <a href="https://github.com/biruk741/portfolio">
    <div class="github">
      Checkout on Github
    </div>
  </a>

  <div class="container">
    <nav></nav>
    <div></div>
    <div class="inner">
      <img src="/assets/pfp.jpg" class="pp">
      <h1>Biruk Mengistu</h1>
      <h3>Software Developer / Student</h3>
    </div>


  </div>
  <div class="body">
    <div class="tabs">
      <div [ngClass]="{'selected':selected==='about','unselected':selected!=='about'}" class="selected" (click)="selected = 'about'">About Me</div>
      <div [ngClass]="{'selected':selected==='education','unselected':selected!=='education'}" (click)="selected = 'education'">Education</div>
      <div [ngClass]="{'selected':selected==='work','unselected':selected!=='work'}" (click)="selected = 'work'">Experience</div>
      <div [ngClass]="{'selected':selected==='portfolio','unselected':selected!=='portfolio'}" (click)="selected = 'portfolio'">Portfolio</div>
      <div [ngClass]="{'selected':selected==='contact','unselected':selected!=='contact'}" (click)="selected = 'contact'">Contact</div>

    </div>
    <div class="pages" [ngSwitch]="selected">
      <div *ngSwitchCase="'about'" [@enterTrigger]="'fadeIn'">
        <app-about ></app-about>
      </div>
      <div *ngSwitchCase="'education'" [@enterTrigger]="'fadeIn'">
        <app-education></app-education>
      </div>
      <div *ngSwitchCase="'work'" [@enterTrigger]="'fadeIn'">
        <app-experience ></app-experience>
      </div>
      <div *ngSwitchCase="'portfolio'" [@enterTrigger]="'fadeIn'">
        <app-portfolio ></app-portfolio>
      </div>
      <div *ngSwitchCase="'contact'" [@enterTrigger]="'fadeIn'">
        <app-contact ></app-contact>

      </div>
    </div>
  </div>

</div>
