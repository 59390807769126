<div class="outer">
  <div class="listitem">
    <div class="img"><img src="/assets/vector-me.png" class="avatar" alt=""></div>
    <div class="right">
      <h1>Hello!</h1>
      <p>My name is Biruk Mengistu and I am a student pursuing computer science at the University of Minnesota. I love to
      create and design functional and intuitive mobile and web applications.
      </p>
    </div>
  </div>
<!--  <div class="listitem" id="code">-->
<!--    <div class="img">-->
<!--      <div class="circle">-->
<!--        <img src="/assets/computer.svg" style="margin-top: 40px;" alt="">-->
<!--      </div>-->

<!--    </div>-->
<!--    <div class="left">-->
<!--      <h1>My Tech Stack</h1>-->
<!--      <p>Tech stacks I have worked with in the past include Angular and React for the front end side,-->
<!--        Spring(Java) and GIN/GORM(GoLang) on the backend, with MongoDB, MySQL and firebase for storage.</p>-->
<!--    </div>-->

<!--  </div>-->
</div>
