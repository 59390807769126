<div class="outer">
  <div class="listitem" id="code">
    <div class="img">
      <div class="circle">
        <img src="/assets/computer.svg" style="margin-top: 40px;" alt="">
      </div>

    </div>
    <div class="left">
      <h1>My Tech Stack</h1>
      <p>Tech stacks I have worked with in the past include Angular and React on the front end side,
        Spring(Java) and GIN/GORM(GoLang) on the backend, with MongoDB, MySQL and firebase for storage.</p>
    </div>

  </div>
</div>
